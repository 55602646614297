
import { computed, defineComponent, PropType, reactive, watch } from '@vue/composition-api'
import { camelCase as toCamelCase, cloneDeep } from 'lodash-es'

import DotIndicator from '@/components/common/CommonDotIndicator.vue'

import { dateDashNotationToDot } from '@/utils/convertDate'

import { PositionOutput } from '@/api/types/position'
import { useGetPositionTransaction } from '@/api/positionTransaction'

export default defineComponent({
  name: 'PositionCommitsDialog',
  components: {
    DotIndicator,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    position: {
      type: Object as PropType<PositionOutput>,
      default: null,
    },
  },
  setup: (props, { root }) => {
    const positionCommits = computed(() => {
      let sortedCommits = cloneDeep(props.position.positionCommits)

      sortedCommits = sortedCommits.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      })

      return sortedCommits
    })

    function isValueBoolean(value: string) {
      return value === 'true' || value === 'false'
    }

    function getTranslationForParameter(parameter: string) {
      return root.$t(`planning.workforcePlanning.table.col.title.${parameter}`)
    }

    function getTranslationForValue(parameter: string, value: string) {
      switch (parameter) {
        case 'sourcingTypes':
          return root.$t(`planning.workforcePlanning.form.sourcing.type.${toCamelCase(value)}`)
        case 'positionStatus':
          return root.$t(`planning.workforcePlanning.form.position.status.${toCamelCase(value)}`)
        default:
          return value
      }
    }

    const { data: transaction, getPositionTransaction, isLoading: isLoadingTransaction } = useGetPositionTransaction()

    watch(
      () => props.position,
      () => {
        if (props.position.sourceTransaction?.id) {
          getPositionTransaction(props.position.sourceTransaction.id)
        }
      },
      { immediate: true }
    )

    return reactive({
      state: {
        positionCommits,

        transaction,
        isLoadingTransaction,
      },
      functions: {
        dateDashNotationToDot,

        isValueBoolean,

        getTranslationForParameter,

        getTranslationForValue,
      },
    })
  },
})
