import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import { PromiseType } from '@/utils/types/PromiseType'
import {
  PositionTransactionOutput,
  PositionTransactionId,
  PositionTransactionInput,
  UseCreatePositionTransaction,
  UseDeletePositionTransaction,
  UseGetPositionTransactions,
  UseUpdatePositionTransaction,
  UseGetPositionTransaction,
} from '@/api/types/positionTransaction'

const VERSION = 'v1'
const RESOURCE = 'position-transactions'

function useGetPositionTransactions(): UseGetPositionTransactions {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetPositionTransactions['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetPositionTransaction(): UseGetPositionTransaction {
  const axios = useAxios<PromiseType<ReturnType<UseGetPositionTransaction['getPositionTransaction']>>>({
    method: 'GET',
  })

  function getPositionTransaction(id: PositionTransactionId): Promise<PositionTransactionOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getPositionTransaction,
  }
}

function useCreatePositionTransaction(): UseCreatePositionTransaction {
  const axios = useAxios<PromiseType<ReturnType<UseCreatePositionTransaction['createPositionTransaction']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createPositionTransaction(data: PositionTransactionInput): Promise<PositionTransactionOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createPositionTransaction,
  }
}

function useUpdatePositionTransaction(): UseUpdatePositionTransaction {
  const axios = useAxios<PromiseType<ReturnType<UseUpdatePositionTransaction['updatePositionTransaction']>>>({
    method: 'PUT',
  })

  function updatePositionTransaction(
    id: PositionTransactionId,
    data: PositionTransactionOutput
  ): Promise<PositionTransactionOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updatePositionTransaction,
  }
}

function useDeletePositionTransaction(): UseDeletePositionTransaction {
  const axios = useAxios<PromiseType<ReturnType<UseDeletePositionTransaction['deletePositionTransaction']>>>({
    method: 'DELETE',
  })

  function deletePositionTransaction(id: PositionTransactionId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deletePositionTransaction,
  }
}

export {
  useGetPositionTransactions,
  useGetPositionTransaction,
  useCreatePositionTransaction,
  useUpdatePositionTransaction,
  useDeletePositionTransaction,
}
