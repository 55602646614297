var render = function () {
  var _vm$position$sourceTr, _vm$state$transaction, _vm$state$transaction2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.value,
      "content-class": "rounded-lg elevation-2",
      "persistent": "",
      "width": "1000"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.$emit('close');
      }
    }
  }, [_c('v-card', {
    attrs: {
      "max-height": "90vh"
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.information.title')) + " ")]), _c('v-card-text', {
    staticClass: "d-flex flex-column"
  }, [!_vm.state.isLoadingTransaction && (_vm$position$sourceTr = _vm.position.sourceTransaction) !== null && _vm$position$sourceTr !== void 0 && _vm$position$sourceTr.id ? _c('div', {
    staticClass: "d-flex flex-column mb-4"
  }, [_c('h2', {
    staticClass: "text-subtitle-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.information.sourceTransaction.description')) + " ")]), _c('span', [_vm._v(_vm._s((_vm$state$transaction = (_vm$state$transaction2 = _vm.state.transaction) === null || _vm$state$transaction2 === void 0 ? void 0 : _vm$state$transaction2.description) !== null && _vm$state$transaction !== void 0 ? _vm$state$transaction : '-'))])]) : _vm._e(), _c('div', [_c('h2', {
    staticClass: "text-subtitle-2 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.information.positionCommits')) + " ")]), _c('v-simple-table', {
    staticClass: "my-5 content__height"
  }, [_c('thead', [_c('tr', [_c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionCommits.createdBy")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionCommits.createdAt")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionCommits.formField")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionCommits.oldValue")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionCommits.newValue")) + " ")]), _c('td', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionCommits.message")) + " ")])])]), _vm.state.positionCommits.length ? _c('tbody', _vm._l(_vm.state.positionCommits, function (commit) {
    return _c('tr', {
      key: commit.id,
      staticClass: "table-row-color"
    }, [_c('td', [_vm._v(" " + _vm._s(commit.createdBy) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(commit.createdAt)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.functions.getTranslationForParameter(commit.parameter)) + " ")]), _c('td', [!_vm.functions.isValueBoolean(commit.oldValue) ? _c('span', [_vm._v(" " + _vm._s(_vm.functions.getTranslationForValue(commit.parameter, commit.oldValue)) + " ")]) : _c('div', {
      staticClass: "d-flex align-center justify-content-start"
    }, [_c('DotIndicator', {
      attrs: {
        "color": "".concat(commit.oldValue ? 'green' : 'red')
      }
    })], 1)]), _c('td', [!_vm.functions.isValueBoolean(commit.newValue) ? _c('span', [_vm._v(" " + _vm._s(_vm.functions.getTranslationForValue(commit.parameter, commit.newValue)) + " ")]) : _c('div', {
      staticClass: "d-flex align-center justify-content-start"
    }, [_c('DotIndicator', {
      attrs: {
        "color": "".concat(commit.newValue ? 'green' : 'red')
      }
    })], 1)]), _c('td', [_vm._v(" " + _vm._s(commit.message) + " ")])]);
  }), 0) : _vm._e(), _c('tbody', [_c('tr', {
    staticClass: "table-row-color-bottom"
  }, [_c('td', [_vm._v(" " + _vm._s(_vm.position.createdBy) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(_vm.position.createdAt)) + " ")]), _c('td', [_vm._v(" - ")]), _c('td', [_vm._v(" - ")]), _c('td', [_vm._v(" - ")]), _c('td', [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionCommits.created")) + " ")])])])])], 1)]), _c('v-card-actions', [_c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "elevation": "0",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.close')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }